<template>
  <div>
    <Row :gutter="8">
      <i-col span="4" class="station_box">
        <div class="m-b-10">
          <Button type="primary" size="small" icon="md-arrow-back" @click="onBackLlist">返回列表设置</Button>
        </div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="24">
            <Select v-model="assetId" clearable size="small" placeholder="选择资产" @on-change="handleChangeAsset">
              <Option v-for="item in assetArray" :key="'asset_' + item.assetId" :value="item.assetId">{{ item.assetName }}
              </Option>
            </Select>
          </i-col>
        </Row>
        <ul class="devcie_list">
          <li v-for="item in showStationArray" :class="curStationId === item.stationId ? 'device_li_actived' : 'device_li_default'"
            :key="'device_' + item.stationId" @click="handleChoiceItem(item)">{{ item.assetName + ' - ' + item.stationName
            }}
          </li>
        </ul>
      </i-col>
      <i-col span="20">
        <SvgStationScreenSet ref="svgStation" :stationId="curStationId" :floors="floors"
          @load-device-info="getPublishingData" @on-select="showInstallInfo" @on-batch-set="batchSetScreen">
        </SvgStationScreenSet>
      </i-col>
    </Row>
    <Modal v-model="setModal" width="810" footer-hide>
      <p slot="header" class="text-center">{{ isBatchSet ? '设置素材' : curDeviceInfo.resourceCode }}</p>
      <Tabs v-model="curTab" :key="tabsKey">
        <TabPane v-if="showScreenDetail" label="素材详情" name="tab_1">
          <img-light-box ref="imgLightBox" :showModal="setModal" :sortIndex="0"></img-light-box>
        </TabPane>
        <TabPane v-if="showSetScreen" label="素材设置" name="tab_2">
          <Card>
            <div>
              <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
              <DatePicker placeholder="选择作业日期" size="small" v-model="actionDate" type="date" style="width:100%"
                :editable="false" :clearable="false"></DatePicker>
            </div>
            <div v-if="curDemandType === 7">
              <h4 class="workplatform-title m-t-10 m-b-20">有无画面填充</h4>
              <Checkbox v-model="noPictureFill">无画面填充</Checkbox>
            </div>
            <set-screen :agreeFileArray="fileArray" :selectedResourceIds="selecteDeviceIds" :curTaskId="curTaskId"
              :selectedTaskitemIds="selectedTaskitemIds" :setOperatingType="setOperatingType" :fileCreateDate="fileDate"
              :isSetFiles="false" :btnSetLoading="btnSetLoading" @parentMethod="handelSubmit">
              <template #pictureDate>
                <Row class="m-b-10">
                  <i-col span="2">素材类型：</i-col>
                  <i-col>
                    <RadioGroup v-model="materialType" type="button" size="small" @on-change="onChangeMaterialType">
                      <Radio v-for="(item, index) in materialTypeArray" :key="'m_' + index + '_' + item.value"
                        :label="item.value">{{ item.name }}
                      </Radio>
                    </RadioGroup>
                  </i-col>
                </Row>
                <Row :gutter="8" class="m-b-10">
                  <i-col span="12">
                    <Select v-model="dateQuery.materialLibrary" size="small" clearable filterable placeholder="选择素材库，可搜索"
                      @on-change="getFileDates">
                      <Option v-for="item in materialLibList" :key="'mLib_' + item" :value="item">{{ item }}</Option>
                    </Select>
                  </i-col>
                  <i-col span="12">
                    <Select v-model="fileDate" size="small" filterable clearable placeholder="选择画面上传日期"
                      @on-change="changeFileDate">
                      <Option v-for="item in fileDateArray" :key="'f_' + item" :value="item">{{ item }}</Option>
                    </Select>
                  </i-col>
                </Row>
                <div class="m-b-10">
                  <Input size="small" v-model="query.keyword" search enter-button placeholder="可输入画面名称关键字搜索"
                    @on-search="onSearchFile" />
                </div>
                <div class="text-center">
                  <Page :total="fileTotal" :current="query.pageNumber" size="small" show-total @on-change="onChangePage" />
                </div>
              </template>
            </set-screen>
          </Card>
        </TabPane>
      </Tabs>
    </Modal>
  </div>
</template>

<script>
// import { agreeFile } from '@/api/order/orderfile'
import { screenSetMixins } from '@/components/installSetting/mixins/screenSet'
import { taskMixins } from '../mixins/index'
import { ParseDate } from '@/utils/dateFormat'
import { agreeFileStat, getFileUploadDateList } from '@/api/msp/file'
import { updateTaskitemFile } from '@/api/msp/demandV3'
import { getFillType } from '@/api/msp/status'
import SvgStationScreenSet from '@/components/svg/SvgStationScreenSet'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import SetScreen from '@/components/task/SetScreen'
export default {
  mixins: [screenSetMixins, taskMixins],
  components: { SvgStationScreenSet, ImgLightBox, SetScreen },
  data () {
    return {
      assetId: null,
      assetArray: [],
      stationArray: [],
      showStationArray: [],
      curStation: {},
      curStationId: -1,
      floors: [],
      deviceInfoArray: [],
      setModal: false,
      curDeviceInfo: {},
      curTab: 'tab_1',
      isBatchSet: false, // 是否为批量设置
      // showScreenDetail: false, // 显示素材详情
      showSetScreen: false, // 显示更改设置
      // 素材设置需要的参数
      tabsKey: 1,
      query: {
        pageNumber: 1,
        pageSize: 15,
        approvalDate: '',
        approvalId: null,
        fileTypes: 1,
        keyword: '',
        status: 5,
        createDate: '',
        type: null
      },
      dateQuery: {
        type: null,
        startDate: '',
        endDate: '',
        materialLibrary: ''
      },
      fileTotal: 0,
      fileDateArray: [],
      materialType: 0,
      materialTypeArray: [
        { value: 0, name: '全部' }
      ],
      fileArray: [],
      fileDate: '',
      actionDate: '',
      selecteDeviceIds: [],
      agreeFileArray: [],
      selectedProductId: -1,
      selectedTaskitemIds: [],
      setOperatingType: 1,
      noPictureFill: false,
      btnSetLoading: false,
      selectedStMediaAttr: {}
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    showScreenDetail () {
      let flag = false
      if (!this.isBatchSet) {
        if ((this.curDeviceInfo.taskitemFileList && this.curDeviceInfo.taskitemFileList.length) || (this.curDeviceInfo.waitSetTaskitemFileList && this.curDeviceInfo.waitSetTaskitemFileList.length)) {
          flag = true
        }
      }
      return flag
    }
  },
  created () {
    this.getStationData()
    this.getMaterialLibData()
  },
  methods: {
    onBackLlist () {
      this.$store.commit('set_show_left_block', true)
      this.$store.commit('set_bottom_component', 'DemandDetial')
    },
    getScreenTypeData () {
      getFillType().then(res => {
        this.materialTypeArray = [
          { value: 0, name: '全部' }
        ]
        if (res && !res.errcode) {
          this.materialTypeArray = this.materialTypeArray.concat(res)
        }
      })
    },
    /**
     * 获取素材上传时间集合
     */
    getFileDates () {
      this.dateQuery.type = this.materialType === 0 ? null : this.materialType
      this.fileDate = ''
      this.fileDateArray = []
      getFileUploadDateList(this.dateQuery).then(res => {
        if (res && !res.errcode) {
          this.fileDateArray = res
          // this.fileDate = this.fileDateArray[0]
          this.onSearchFile()
        } else {
          this.fileDateArray = []
        }
      })
    },
    /**
     * 获取已通过审核的画面文件
     */
    getFileData () {
      this.fileArray = []
      this.fileTotal = 0
      this.query.type = this.materialType === 0 ? null : this.materialType
      this.query.createDate = this.fileDate || ''
      this.query.materialLibrary = this.dateQuery.materialLibrary
      agreeFileStat(this.query).then(res => {
        if (res && !res.errcode) {
          this.fileArray = res.fileVOList.list
          this.fileTotal = res.fileVOList.totalRow
          this.query.pageNumber = res.fileVOList.pageNumber
        } else {
          this.fileTotal = 0
          this.query.pageNumber = 1
        }
      })
    },
    onChangeMaterialType () {
      const type = this.materialType === 0 ? undefined : this.materialType
      this.dateQuery.materialLibrary = ''
      this.fileDate = ''
      this.getMaterialLibData(type)
      this.getFileDates()
    },
    onSearchFile () {
      this.query.pageNumber = 1
      this.getFileData()
    },
    onChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getFileData()
    },
    changeFileDate () {
      this.onSearchFile()
    },
    showInstallInfo (deviceInfo) {
      this.tabsKey++
      this.isBatchSet = false
      this.curDeviceInfo = deviceInfo
      this.curTab = 'tab_1'
      this.setOperatingType = 2
      this.showSetScreen = this.curDeviceInfo.publishWithNotSetDeviceFlag === 2 && this.curDeviceInfo.taskStatus === -2
      this.selecteDeviceIds = [deviceInfo.deviceId]
      this.selectedTaskitemIds = [deviceInfo.waitSetTaskitemId]
      const postData = {
        startIndex: 0,
        imgList: this.curDeviceInfo.publishWithNotSetDeviceFlag === 1 ? this.curDeviceInfo.taskitemFileList : this.curDeviceInfo.waitSetTaskitemFileList
      }
      this.$nextTick(() => {
        this.$refs.imgLightBox.init(postData)
      })
      if (this.showSetScreen) {
        this.getScreenTypeData()
        this.getFileDates()
      }
      this.setModal = true
    },
    batchSetScreen (selecteDeviceInfoList) {
      this.tabsKey++
      this.isBatchSet = true
      this.showSetScreen = true
      this.curTab = 'tab_2'
      this.setOperatingType = 2
      // 选中的设备任务信息
      this.selecteDeviceIds = selecteDeviceInfoList.map(x => x.deviceId)
      // this.$store.commit('set_operating_type', this.setOperatingType)
      this.selectedTaskitemIds = selecteDeviceInfoList.map(x => x.waitSetTaskitemId)
      this.getScreenTypeData()
      this.getFileDates()
      this.setModal = true
    },
    handelSubmit (data) {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }

      if (data && data instanceof Object) {
        this.btnSetLoading = true
        data.taskId = this.curTaskId
        data.actionDate = ParseDate(this.actionDate)
        if (this.curDemandType === 7) {
          data = Object.assign({ noPictureFill: this.noPictureFill }, data)
        }

        updateTaskitemFile(data).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.handleSubmitSuccess()
          }
        }).finally(() => {
          this.setModal = false
          this.modalKey++
        })
      } else {
        this.$Notice.error({ desc: '非法请求' })
      }
    },
    /**
     * 处理成功提交后事件
     */
    handleSubmitSuccess () {
      // 更新store中数据
      this.selecteDeviceIds = []
      this.selectedTaskitemIds = []
      // 调用列表数据获取函数
      this.stationSvgInit()

      this.$Notice.success({ desc: '操作成功' })
    }
  }
}
</script>

<style lang="less">
.station_box {
  border-right: 1px dotted #777777;
  min-height: 100vh;
}

</style>
